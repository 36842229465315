import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Download from "./components/Content/Download";
import Second from "./components/Content/Second";
import Third from "./components/Content/Third";
import Reader from "./components/Content/Reader";
import About from "./components/About/About";
import { userLogin } from "./redux/slices/userSlice";
import { connect } from "react-redux";
import Help from "./components/Help/Help";
import Trade from "./components/Toolkit/Trade";

export class ConnectedApp extends Component {
  componentDidMount() {
    this.props.userLogin();
  }

  render() {
    return (
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/download" element={<Download />}></Route>
          <Route path="/second" element={<Second />}></Route>
          <Route path="/third" element={<Third />}></Route>
          <Route path="/reader" element={<Reader />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/trade" element={<Trade />}></Route>
        </Routes>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { userLogin: () => dispatch(userLogin()) };
};

const App = connect(null, mapDispatchToProps)(ConnectedApp);

export default App;
