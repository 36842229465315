import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './sticky-footer-navbar.css';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Container fluid={true}>
                    <span className="text-muted">Copyright @ 2007-2023 Alien Laboratory.</span>
                </Container>
            </footer>
        );
    }
}