import React, { Component } from "react";
import { Badge } from "reactstrap";
import { isIPv6 } from 'is-ip';
import logo from "../../logo.svg";
import "./About.css";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServerIP: '',
      IPv6Visible: false
    };
  }

  componentDidMount() {
    fetch(`/api/server/SeeIP`)
      .then(res => res.text())
      .then((result) => {
        if (isIPv6(result)) {
          this.setState({
            ServerIP: result,
            IPv6Visible: true
          });
        }
      }, (error) => {
        console.error(error)
      })
  }

  render() {
    const IPv6Url = `http://[${this.state.ServerIP}]`
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Alien Laboratory 官方网站 {this.state.IPv6Visible ? <Badge color="success"
            title='IPv6'
            href={IPv6Url}
            target="_blank"
            rel="noopener noreferrer">IPv6</Badge> : null}
          </p>
          <small>
            <code>Powered by <a className="App-link"
              href="https://react.dev/"
              target="_blank"
              rel="noopener noreferrer">React</a> & <a className="App-link"
                href="https://getbootstrap.com/"
                target="_blank"
                rel="noopener noreferrer">Bootstrap</a>
            </code>
          </small>
        </header>
      </div>
    );
  }
}
