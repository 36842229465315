import React, { Component } from "react";
import { Card, CardTitle, CardText, Row, Col, Button, Badge } from "reactstrap";
import moment from 'moment';
import Screenshot_x64 from "./img/RARPG_v8_x64.png";
import Screenshot_x86 from "./img/RARPG_v8_x86.png";

export default class Download extends Component {

    constructor(props) {
        super(props);
        this.V8FileName = "RarPswdEnum_v8.0.0-beta6_x64.zip";
        this.V8x86FileName = "RarPswdEnum_v8.0.0-beta6_x86.zip";
        this.V78FileName = "RarPswdEnum_v7.8_Win64_32.zip";
        this.state = {
            V8Updated: 'Loading',
            V8x86Updated: 'Loading',
            V78Updated: 'Loading'
        };
    }

    componentDidMount() {
        fetch(`/api/download/fileinfo/${this.V8FileName}`)
            .then(res => res.json())
            .then((result) => {
                let dateTime = moment(result.lastUpdated);
                this.setState({
                    V8Updated: dateTime.format('L')
                });
            }, (error) => {
                console.error(error)
            })
        fetch(`/api/download/fileinfo/${this.V8x86FileName}`)
            .then(res => res.json())
            .then((result) => {
                let dateTime = moment(result.lastUpdated);
                this.setState({
                    V8x86Updated: dateTime.format('L')
                });
            }, (error) => {
                console.error(error)
            })
        fetch(`/api/download/fileinfo/${this.V78FileName}`)
            .then(res => res.json())
            .then((result) => {
                let dateTime = moment(result.lastUpdated);
                this.setState({
                    V78Updated: dateTime.format('L')
                });
            }, (error) => {
                console.error(error)
            })
    }

    render() {
        const V8URL = `/api/download/${this.V8FileName}`;
        const V8x86URL = `/api/download/${this.V8x86FileName}`;
        const V78URL = `/api/download/${this.V78FileName}`;
        return (
            <div style={{ marginTop: "3px", marginBottom: "3px" }}>
                <Row>
                    <Col sm="6">
                        <Card body style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <CardTitle tag="h5">
                                RAR Password Generator v8.0 x64 <Badge color="success">最新推荐版</Badge>
                            </CardTitle>
                            <CardText>最新 v8.0.0-beta6 x64 公测版</CardText>
                            <CardText>
                                支持平台：Windows Vista / 7 / 8 / 8.1 / 10 / 11（64位操作系统）
                            </CardText>
                            <CardText>
                                <small className="text-muted">Last updated on {this.state.V8Updated}</small>
                            </CardText>
                            <img
                                src={Screenshot_x64}
                                className="img-fluid"
                                alt="软件预览"
                                style={{
                                    maxWidth: "886px",
                                    margin: "3px",
                                }}
                            ></img>
                            <Button
                                color="primary"
                                href={V8URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                下载
                            </Button>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card body style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <CardTitle tag="h5">
                                RAR Password Generator v8.0 x86 <Badge color="warning">仅限32位操作系统</Badge>
                            </CardTitle>
                            <CardText>最新 v8.0.0-beta6 x86 公测版</CardText>
                            <CardText>
                                支持平台：Windows Vista / 7 / 8 / 8.1 / 10 / 11（32位操作系统）
                            </CardText>
                            <CardText>
                                <small className="text-muted">Last updated on {this.state.V8x86Updated}</small>
                            </CardText>{" "}
                            <img
                                src={Screenshot_x86}
                                className="img-fluid"
                                alt="软件预览"
                                style={{
                                    maxWidth: "886px",
                                    margin: "3px",
                                }}
                            ></img>
                            <Button
                                color="primary"
                                href={V8x86URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                下载
                            </Button>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card body style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <CardTitle tag="h5">
                                RAR Password Enumerator v7.8 <Badge color="danger">旧版本</Badge>
                            </CardTitle>
                            <CardText>最新 v7.8 稳定版</CardText>
                            <CardText>
                                支持平台：Windows XP / Vista / 7 / 8 / 8.1 / 10 / 11
                            </CardText>
                            <CardText>
                                <small className="text-muted">
                                    Windows XP / Vista / 7 需安装 .NET Framework 4.0 支持
                                </small>
                            </CardText>
                            <CardText>
                                <small className="text-muted">Last updated on {this.state.V78Updated}</small>
                            </CardText>
                            <Button
                                color="primary"
                                href={V78URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                下载
                            </Button>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card body style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <CardTitle tag="h5">
                                RAR Password Enumerator v7.6.1 <Badge color="secondary">已停止支持</Badge>
                            </CardTitle>
                            <CardText>v7.6.1 以及更旧版本目前不提供下载</CardText>
                            <CardText>
                                支持平台：Windows XP / Vista / 7 / 8 / 8.1 / 10 / 11
                            </CardText>
                            <CardText>
                                <small className="text-muted">
                                    Windows XP / Vista / 7 需安装 .NET Framework 4.0 支持
                                </small>
                            </CardText>
                            <CardText>
                                <small className="text-muted">Last updated on 5/1/2023</small>
                            </CardText>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
