import { useState } from "react";
import { Form, FormGroup, Label, Row, Col, Input, Button } from "reactstrap";

export default function TradeCalculator(props) {

    const btnColor = props.color ?? 'success';
    const action = props.action;
    var buyOrSellStorage = {
        price1: 100,
        qty1: 10,
        price2: 108,
        qty2: 8,
        fee: 5
    };
    if (action === 'Buy') {
        buyOrSellStorage = JSON.parse(window.localStorage.getItem("newBuyStorage")) ?? buyOrSellStorage;
    } else if (action === 'Sell') {
        buyOrSellStorage = JSON.parse(window.localStorage.getItem("newSellStorage")) ?? buyOrSellStorage;
    }

    const [price1, setPrice1] = useState(buyOrSellStorage.price1);
    const [qty1, setQty1] = useState(buyOrSellStorage.qty1);
    const [price2, setPrice2] = useState(buyOrSellStorage.price2);
    const [qty2, setQty2] = useState(buyOrSellStorage.qty2);
    const [newPrice, setNewPrice] = useState('');
    const [newQty, setNewQty] = useState('');
    const [fee, setFee] = useState(buyOrSellStorage.fee);

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="price_1">
                                Initial Price
                            </Label>
                            <Input
                                id="price_1"
                                name="price1"
                                placeholder="the initial price"
                                type="number"
                                value={price1}
                                onChange={e => setPrice1(e.target.value)}
                                step="0.1"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="quantity_1">
                                Initial Quantity
                            </Label>
                            <Input
                                id="quantity_1"
                                name="quantity1"
                                placeholder="the initial quantity"
                                type="number"
                                value={qty1}
                                onChange={e => setQty1(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="price_2">
                                {action} Price
                            </Label>
                            <Input
                                id="price_2"
                                name="price2"
                                placeholder="to buy/sell price"
                                type="number"
                                value={price2}
                                onChange={e => setPrice2(e.target.value)}
                                step="0.1"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="quantity_2">
                                {action} Quantity
                            </Label>
                            <Input
                                id="quantity_2"
                                name="quantity2"
                                placeholder="to buy/sell quantity"
                                type="number"
                                value={qty2}
                                onChange={e => setQty2(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="fee">
                                Est Fee
                            </Label>
                            <Input
                                id="fee"
                                name="est_fee"
                                placeholder="est fee"
                                type="number"
                                value={fee}
                                onChange={e => setFee(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <Button color={btnColor} onClick={() => {
                let newPrice = 0;
                let newQty = 0;
                if (action === 'Buy') {
                    newPrice = (Number(price1) * Number(qty1) + Number(price2) * Number(qty2) + Number(fee)) / (Number(qty1) + Number(qty2))
                    newQty = Number(qty1) + Number(qty2)

                    const newBuyStorage = {
                        price1: price1,
                        qty1: qty1,
                        price2: price2,
                        qty2: qty2,
                        fee: fee
                    }
                    localStorage.setItem("newBuyStorage", JSON.stringify(newBuyStorage));
                }
                else if (action === 'Sell') {
                    newPrice = (Number(price1) * Number(qty1) - Number(price2) * Number(qty2) + Number(fee)) / (Number(qty1) - Number(qty2))
                    newQty = Number(qty1) - Number(qty2)

                    const newSellStorage = {
                        price1: price1,
                        qty1: qty1,
                        price2: price2,
                        qty2: qty2,
                        fee: fee
                    }
                    localStorage.setItem("newSellStorage", JSON.stringify(newSellStorage));
                }
                setNewPrice(newPrice.toFixed(2))
                setNewQty(newQty)
            }}>
                Calculate
            </Button>
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="new_price">
                                New Price
                            </Label>
                            <Input
                                id="new_price"
                                name="price_new"
                                placeholder="new price"
                                readOnly={true}
                                value={newPrice}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="new_qty">
                                New Quantity
                            </Label>
                            <Input
                                id="new_qty"
                                name="new_quantity"
                                placeholder="new quantity"
                                readOnly={true}
                                value={newQty}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
